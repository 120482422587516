<template>
  <div class="Info">
    <div class="courseInfo">
      <div class="col">
        <div class="infoItem">
          <span>授课老师</span><span>{{ courseInfo.instructor_name }}</span>
        </div>
        <div class="infoItem ">
          <span>开课时间</span><span>{{ courseInfo.opening_time }}</span>
        </div>
      </div>
      <div class="col pl40">
        <div class="infoItem">
          <span>授课形式</span
          ><span>{{ cusType[courseInfo.course_type] }}</span>
        </div>
        
        <!-- <div class="infoItem">
          <span>课程名称:</span><span>{{ courseInfo.course_name }}</span>
        </div> -->
        <div class="infoItem">
          <span>培训地址</span><span>{{ courseInfo.training_addr }}</span>
          <div class="line" v-if="!courseInfo.training_addr"> — </div>
        </div>
      </div>
      <!-- <div class="col">
        <div class="infoItem flex-end">
          <span>培训地点:</span><span>{{ courseInfo.training_addr }}</span>
        </div>
        <div class="infoItem flex-end">
          <span>培训要求:</span><span>{{ courseInfo.require }}</span>
        </div>
      </div> -->
    </div>
  </div>
</template>
<script>
export default {
  props: ["courseInfo"],
  data() {
    return {
      cusType: {
        1: "线上授课",
        2: "线下授课",
        3: "线上+线下",
      },
    };
  },
};
</script>
<style scoped lang="scss">
.Info {
  // width: 68.7%;
  width: 756px;
  background: #ffffff;
  display: flex;
  // margin-top: 40px;
  // margin-bottom: 80px;
  // box-shadow: 0px 0px 9px 3px rgba(204, 221, 244, 0.5);
  // border-radius: 20px;
}
.courseInfo {
  width: 100%;
  // display: grid;
  // grid-template-columns: 1fr 1fr 1fr;
  // padding: 10px;
  display: flex;
  // justify-content: space-between;
  box-sizing: border-box;
  .col{
    width: 50%;
  }
  .infoItem {
    // width: 20%;
    // background: red;
    width: 100%;
    font-size: 14px;
    padding: 20px 20px 20px 0px;
    box-sizing: border-box;
    display: flex;
    > span {
      font-size: 12px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #404040;
      white-space: nowrap;
      // min-width: 100px;
      display: block;
    }
    > span:nth-child(1){
      font-size: 12px;
      color: #8896A7;
      margin-left: 0px;
    }
    .line {
      width: 12px;
      height: 12px;
      font-size: 12px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #8896A7;
    }
  }
  .infoItem span:nth-child(odd) {
    margin-right: 10px;
  }
}
.pl40{
  padding-left: 0px;
}
.flex-end{
  justify-content: flex-end;
}
.flex-center{
  justify-content: center;
}
.courseInfo :nth-child(3) {
  text-align: right;
}

@media screen and (max-width: 768px) {
}
</style>